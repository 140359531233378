import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { IkszrApiService } from 'src/app/core/services/ikszr-api/ikszr-api.service';
import { Chart } from 'chart.js';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-parking-facility-history',
  templateUrl: './parking-facility-history.component.html',
  styleUrls: ['./parking-facility-history.component.scss'],
})
export class ParkingFacilityHistoryComponent implements OnInit {

    @ViewChild('parkingFacilityChart') parkingFacilityChartCanvas;
    parkingFacilityChart: any;

    entity : IkszrEntity = new IkszrEntity()
    langPack : any

    externalId : string
    feePerDimension: string = ''

    currentImage: {
      image: string,
      createdAt: string
    }

    prepared : boolean = false

    labels : Array<any> = []
    values : Array<any> = []

    selectedDay : string =  (new Date().getDay()).toString()

    refreshSubject : BehaviorSubject<boolean> = new BehaviorSubject(true)

    constructor(public route: ActivatedRoute, public router:Router, public userService:UserService, public ikszrApiService:IkszrApiService) {

    }

    ngOnInit() {
      this.getLangPack()
      this.route.queryParams.subscribe(async (params:any) => {
        this.externalId = params.id
        await this.refreshChart(this.externalId, Number(this.selectedDay))
        this.refreshSubject.next(true)
        this.refreshSubject.subscribe(async (val:boolean) => {
          let getRes = await this.ikszrApiService.get(this.externalId, "parking-facilities")
          if(!getRes.error) this.entity = getRes.data.instance
          if (!this.entity.time_dimension) {
            this.feePerDimension = this.langPack.freeOfCharge
          } else if (this.entity.time_dimension === 'óra') {
            this.feePerDimension = `${this.entity.fee} Ft/${this.langPack.timeHour}`
          } else if (this.entity.time_dimension === 'alkalom') {
            this.feePerDimension = `${this.entity.fee} Ft/${this.langPack.timeOccasion}`
          }
          this.prepared = true
        })
      })
    }

    async refreshChart(externalId: string, dayOfWeek: number)
    {
      let chartRes = await this.ikszrApiService.getParkingFacilityHistory(externalId, dayOfWeek)
      if(!chartRes.error)
      {
        this.labels = chartRes.data.labels
        this.values = chartRes.data.values

        setTimeout(() => {
          var canvas : any = document.getElementById('parkingFacilityChart') as HTMLElement;
          this.parkingFacilityChart = new Chart(canvas, {
            type: 'bar',
            data: {
              labels: this.labels,
              datasets: [{
                label: this.langPack.chartLabel,
                data: this.values,
                borderWidth: 1
              }]
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero:true
                  }
                }]
              }
            }
          })
        }, 800)

      } else {
        console.error('refreshChart error:', chartRes.error)
      }
    }

    async onChange()
    {
      this.prepared = false
      await this.refreshChart(this.externalId, Number(this.selectedDay))
      this.prepared = true
    }

    getLangPack() : boolean {
      let lang = localStorage.getItem('lang')
      if(AppConfig.LANGS.indexOf(lang) < 0)
      {
         localStorage.setItem('lang', 'hu')
         lang='hu'
      }
      this.langPack = this.langPacks[lang]
      return true
    }

    newNotification()
    {
      this.router.navigate(['/notification/new'], { queryParams: { id: this.entity.externalId, type:this.entity.type , pfnav: 1}})
    }

    langPacks = {
      hu: {
        menuItemText: 'Parkoló',
        loading: 'Betöltés...',
        chartName: 'Szabad helyek száma',
        chartLabel: 'Szabad helyek száma',
        dayOfWeek: 'Nap',
        showOnMap: 'Térkép',
        availableSpots: 'Szabad helyek',
        updated: 'Frissítve',
        dayLabel: 'Nap',
        paymentMethods: 'Fizetési módok',
        mon: 'Hétfő',
        tue: 'Kedd',
        wed: 'Szerda',
        thu: 'Csütörtök',
        fri: 'Péntek',
        sat: 'Szombat',
        sun: 'Vasárnap',
        cancelText: 'Mégse',
        okText: 'OK',
        eCharger: 'Elektromos töltőhelyek száma',
        fee: 'Parkolási díj',
        openingHours: 'Nyitvatartási idő',
        paymentWithMachine: 'Díjfizetés helyben, parkolóautomatával',
        paymentWithApp: 'Díjfizetés mobilapplikációval ',
        paymentWithSms: 'Díjfizetés sms-ben',
        paymentWithCall: 'Díjfizetés telefonhívással',
        paymentWithParkl: 'Díjfizetés Parkl alkalmazással',
        back: 'Vissza',
        freeOfCharge: 'ingyenes',
        timeHour: 'óra',
        timeOccasion: 'alk.',
        inactiveEntity: 'Inaktív létesítmény'
      },
      en: {
        menuItemText: 'Parking Facility',
        loading: 'Loading...',
        chartName: 'Free places',
        chartLabel: 'Free places',
        dayOfWeek: 'Free places',
        showOnMap: 'Show on map',
        availableSpots: 'Free places',
        updated: 'Updated at',
        dayLabel: 'Day',
        paymentMethods: 'Payment methods',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
        sun: 'Sunday',
        cancelText: 'Cancel',
        okText: 'OK',
        eCharger: 'Amount of E-chargers',
        fee: 'Parking fee',
        openingHours: 'Opening hours',
        paymentWithMachine: 'Parking automat',
        paymentWithApp: 'Application',
        paymentWithSms: 'Text message',
        paymentWithCall: 'Phone call',
        paymentWithParkl: 'Parkl application',
        back: 'Back',
        freeOfCharge: 'Free of charge',
        timeHour: 'hour',
        timeOccasion: 'time',
        inactiveEntity: 'Inactive facility'
      }
    }

}
