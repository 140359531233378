import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiRequestService } from '../api-request/api-request.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config/app-config.config';
import { User } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  public currentUser : User = new User()
  public isLoggedIn : boolean = false
  public firstOpen : boolean = true
  constructor(public apiRequestService:ApiRequestService, public http:HttpClient) {

  }

  public hasValidToken(): boolean {
    let jwtHelper = new JwtHelperService()
    let token = localStorage.getItem('token')
    if(!token || !token.match(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/)) return false
    return !jwtHelper.isTokenExpired(token)
  }

  public async login(email: string, password: string): Promise<{error:string, data:any}> {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {
      this.http.post(AppConfig.API_URL + '/user/login', {email:email, password:password}).subscribe(
        (res:any) => {
          this.handleSuccessfulLogin(res.data.token, res.data.user)
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          return resolve({error:failRes.error.message})
        }
      )

    })
  }

  public async resetPassword(email: string): Promise<{error:string, data:any}> {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {
      this.http.get(AppConfig.API_URL + '/user/resetpassword?email='+email, {}).subscribe(
        (res:any) => {
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          return resolve({error:failRes.error.message})
        }
      )

    })
  }

  public async register(data: any): Promise<{error:string, data:any}> {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {
      this.http.post(AppConfig.API_URL + '/user/register', data).subscribe(
        (res:any) => {
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          return resolve({error:failRes.error.message})
        }
      )
    })
  }

  public async changePassword(oldPassword: string, newPassword: string)
  {
    let res = await this.apiRequestService.post('/user/newpassword', {oldPassword:oldPassword, newPassword:newPassword})

    if(res.error) {
      console.error('UserService error (changePassword):', res.error)
      return {error: res.error}
    } else {
      return {error: null}
    }
  }

  public async fcmToken(token: string)
  {
    let res = await this.apiRequestService.post('/user/fcmtoken', {token:token})

    if(res.error) {
      console.error('UserService error (fcmToken):', res.error)
      return {error: res.error}
    } else {
      return {error: null}
    }
  }

  public async resendActivation(email: string): Promise<{error:string, data:any}> {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {
      this.http.get(AppConfig.API_URL + '/user/activate/resendmail?email='+email, {}).subscribe(
        (res:any) => {
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          return resolve({error:failRes.error.message})
        }
      )
    })
  }

  public handleSuccessfulLogin(token:string, userData: any): boolean {
    this.currentUser = this.reqDataToInstace(userData)
    localStorage.setItem('userData', JSON.stringify(this.currentUser))
    localStorage.setItem('token', token)
    this.isLoggedIn = true
    return true
  }

  public async checkToken(): Promise<{error:string, data:any}> {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {

      if(!this.hasValidToken()) return resolve({error:'Valid token missing!'})

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })


      let options = {
        headers: headers
      }

      this.http.post(AppConfig.API_URL + '/user/token', {foo:'bar'}, options).subscribe(
        (res:any) => {
          this.handleSuccessfulLogin(res.data.token, res.data.user)
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          this.logout()
          return resolve({error:failRes.error.message})
        }
      )
    })
  }

  public logout()
  {
    localStorage.clear()
    this.isLoggedIn = false
    return true
  }

  //req to obj
  private reqDataToInstace(data:any) : User {
    let instance = new User()
    if(data.firstName) instance.firstName = data.firstName
    if(data.lastName) instance.lastName = data.lastName
    if(data.email) instance.email = data.email
    if(data.activated) instance.activated = data.activated
    if(data.id) instance.id = data.id
    return instance
  }

}
