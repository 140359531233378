import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { Router } from '@angular/router';
import { EsriMapService } from 'src/app/core/services/esri-map/esri-map.service';

@Component({
  selector: 'app-show-on-map',
  templateUrl: './show-on-map.component.html',
  styleUrls: ['./show-on-map.component.scss'],
})
export class ShowOnMapComponent implements OnInit {
  @Input() ikszrEntity : IkszrEntity = new IkszrEntity()
  langPack : any

  constructor(
    public router:Router,
    public platform: Platform,
    public esriMapService: EsriMapService
  ) { }

  ngOnInit() {
    this.getLangPack()
  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  showOnMap()
  {
    if (this.platform.is('desktop') && this.platform.width() >= 800) {
      this.esriMapService.mapView.goTo({
        center: [parseFloat(this.ikszrEntity.position.lon), parseFloat(this.ikszrEntity.position.lat)],
        zoom: 18
      });
    } else {
      this.router.navigate(['/map'], {queryParams: {id: this.ikszrEntity.externalId, type:  this.ikszrEntity.type}})
    }
  }

  langPacks = {
    hu: {
      showOnMap: 'Mutasd térképen'
    },
    en: {
      showOnMap: 'Show on map'
    }
  }
}
