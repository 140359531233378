import { Component, OnInit, Input } from '@angular/core';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { LoadingController, AlertController } from '@ionic/angular';
import { UserService } from 'src/app/core/services/user/user.service';
import { BookmarkService } from 'src/app/core/services/bookmark/bookmark.service';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-delete-bookmark',
  templateUrl: './delete-bookmark.component.html',
  styleUrls: ['./delete-bookmark.component.scss'],
})
export class DeleteBookmarkComponent implements OnInit {

  @Input() ikszrEntity = new IkszrEntity()
  @Input() refreshSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)

  lang :string = 'hu'
  langPack : any

  constructor(public bookmarkService:BookmarkService,
    public alertController:AlertController,
    public userService: UserService,
    public loadingController: LoadingController) { }

  ngOnInit() {
    this.getLangPack()
  }

  async onDeleteBookmark()
  {
    const loading = await this.loadingController.create({
      message: this.langPack.loading,
    })
    await loading.present()

    let res = await this.bookmarkService.delete(this.ikszrEntity.externalId)
    await loading.dismiss()

    if(res.error) {
      const alert = await this.alertController.create({
        header: this.langPack.error,
        subHeader: this.langPack.unknownError,
        buttons: [this.langPack.close]
      })
      await alert.present()
    } else {
      const alert = await this.alertController.create({
        header: this.langPack.success,
        subHeader: this.langPack.bookmarkDeleted,
        buttons: [this.langPack.close]
      })
      this.refreshSubject.next(true)
      await alert.present()
    }

  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  langPacks = {
    hu: {
      deleteBookmark: 'Kedvenc törlése',
      loading: 'Kedvenc törlése...',
      error: 'Hiba',
      success: 'Siker',
      bookmarkDeleted: 'Törölve a kedvencek közül.',
      unknownError: 'Ismeretlen hiba!',
      close: 'Bezár'
    },
    en: {
      deleteBookmark: 'Remove from Favorites',
      loading: 'Removing item from Favorites...',
      bookmarkDeleted: 'Removed from Favorites.',
      success: 'Success',
      error: 'Error',
      unknownError: 'Unknown error',
      close: 'Close'
    }
  }

}
