import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { VjtImageComponent } from './components/vjt-image/vjt-image.component';
import { ParkingFacilityInfoComponent } from './components/parking-facility-info/parking-facility-info.component';
import { AddBookmarkComponent } from './components/add-bookmark/add-bookmark.component';
import { DeleteBookmarkComponent } from './components/delete-bookmark/delete-bookmark.component';
import { CameraModalComponent } from './components/camera-modal/camera-modal.component';
import { CameraImageComponent } from './components/camera-image/camera-image.component';
import { CameraHistoryComponent } from './components/camera-history/camera-history.component';
import { ParkingFacilityHistoryComponent } from './components/parking-facility-history/parking-facility-history.component';
import { ShowOnMapComponent } from './components/show-on-map/show-on-map.component';
import { NavigateToComponent } from './components/navigate-to/navigate-to.component';
import { VjtInfoComponent } from './components/vjt-info/vjt-info.component';

@NgModule({
  declarations: [
    NavbarComponent,
    VjtImageComponent,
    ParkingFacilityInfoComponent,
    CameraImageComponent,
    AddBookmarkComponent,
    DeleteBookmarkComponent,
    CameraModalComponent,
    CameraHistoryComponent,
    ParkingFacilityHistoryComponent,
    ShowOnMapComponent,
    NavigateToComponent,
    VjtInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
  ],
  entryComponents: [

  ],
  exports: [
    NavbarComponent,
    VjtImageComponent,
    ParkingFacilityInfoComponent,
    AddBookmarkComponent,
    DeleteBookmarkComponent,
    CameraImageComponent,
    CameraHistoryComponent,
    ParkingFacilityHistoryComponent,
    ShowOnMapComponent,
    NavigateToComponent,
    VjtInfoComponent
  ]
})
export class SharedModule { }
