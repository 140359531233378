import { Component, OnInit, Input } from '@angular/core';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { BookmarkService } from 'src/app/core/services/bookmark/bookmark.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { UserService } from 'src/app/core/services/user/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-add-bookmark',
  templateUrl: './add-bookmark.component.html',
  styleUrls: ['./add-bookmark.component.scss'],
})
export class AddBookmarkComponent implements OnInit {
  @Input() ikszrEntity = new IkszrEntity()
  @Input() refreshSubject : BehaviorSubject<boolean> = new BehaviorSubject(true)
  lang :string = 'hu'
  langPack : any

  constructor(public bookmarkService:BookmarkService,
    public alertController:AlertController,
    public userService: UserService,
    public loadingController: LoadingController) { }

  ngOnInit() {
    this.getLangPack()
  }

  async onAddBookmark()
  {
    const loading = await this.loadingController.create({
      message: this.langPack.loading,
    })
    await loading.present()

    let res = await this.bookmarkService.create(this.ikszrEntity.externalId,  this.ikszrEntity.type)
    await loading.dismiss()

    if(res.error) {
      const alert = await this.alertController.create({
        header: this.langPack.error,
        subHeader: res.error == 'alreadyBookmark' ? this.langPack.existingBookmark : this.langPack.unknownError,
        buttons: [this.langPack.close]
      })
      await alert.present()
    } else {
      const alert = await this.alertController.create({
        header: this.langPack.success,
        subHeader: this.langPack.bookmarkAdded,
        buttons: [this.langPack.close]
      })
      await alert.present()
      this.refreshSubject.next(true)
    }

  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  langPacks = {
    hu: {
      addBookmark: 'Kedvencekhez adás',
      loading: 'Kedvenc hozzáadása...',
      error: 'Hiba',
      success: 'Siker',
      bookmarkAdded: 'Hozzáadva a kedvencekhez.',
      existingBookmark: 'Már a kedvencek között!',
      unknownError: 'Ismeretlen hiba!',
      close: 'Bezár'
    },
    en: {
      addBookmark: 'Add to Favorites',
      loading: 'Saving item as Favorite...',
      bookmarkAdded: 'Added to Favorites.',
      success: 'Success',
      error: 'Error',
      existingBookmark: 'Item already among Favorites!',
      unknownError: 'Unknown error',
      close: 'Close'
    }
  }

}
