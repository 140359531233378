import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { BehaviorSubject } from 'rxjs';
import { IkszrApiService } from '../ikszr-api/ikszr-api.service';
import { loadModules, loadCss } from 'esri-loader';
import esri = __esri;
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class EsriMapService {

  public map : any = undefined
  public mapView : any = undefined
  langPack : any

  mapEl: ElementRef;
  isMapHidden : boolean = true

  mapReady : BehaviorSubject<boolean> = new BehaviorSubject(false)
  mapEventSubscribed : boolean = false

  options = {
    dojoConfig: {
      async: true,
      packages: [
        {
          location: "/assets/fcl",
          name: 'fcl'
        }
      ]
    }
  };

  langPacks = {
    hu: {
      cameraLayer: 'Kamerák',
      vjtLayer: 'Változó jelzésképű táblák',
      parkingFacilityLayer: 'Parkolók',
      chargerLayer: 'Elektromos töltőpontok',
      parkingZoneLayer: 'Parkolási övezetek',
      restrictedZoneLayer: 'Forgalomcsillapított övezetek',
      maintainedRoadLayer: 'Budapest Közút Zrt. által üzemeltetett utak',
      trafficLightLayer: 'Jelzőlámpás csomópontok'
    },
    en: {
      cameraLayer: 'Cameras',
      vjtLayer: 'Variable message signs',
      parkingFacilityLayer: 'Parking facilities',
      chargerLayer: 'Electric chargers',
      parkingZoneLayer: 'Parking zones',
      restrictedZoneLayer: 'Restricted zones',
      maintainedRoadLayer: 'Roads maintained by Budapest Közút Zrt.',
      trafficLightLayer: 'Traffic lights'
    }
  }


  constructor(public ikszrApiService:IkszrApiService, public platform: Platform, public activatedRoute: ActivatedRoute, public router: Router) {

    this.getLangPack()

  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  createHash(string: String): number{
    var hash = 0, i, chr;
    if (string.length === 0) return hash;
    for (i = 0; i < string.length; i++) {
      chr   = string.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; 
    }
    return (hash - 1) % 9 + 1
  };

  async getGeo() {

    return new Promise<any>(async(resolve:Function) => {
      //console.log("get geo....")
      await this.platform.ready();
      const [Map, MapView, FeatureLayer, Field, Graphic, GraphicsLayer, LayerList, Track,SpatialReference, Expand, Zoom, TileLayer,TextSymbol,Font,Color,ClassBreaksRenderer, PictureMarkerSymbol, fcl]:any = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/FeatureLayer',
        'esri/layers/support/Field',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/widgets/LayerList',
        'esri/widgets/Track',
        'esri/geometry/SpatialReference',
        'esri/widgets/Expand',
        'esri/widgets/Zoom',
        'esri/layers/TileLayer',
        'esri/symbols/TextSymbol',
        'esri/symbols/Font',
        'esri/Color',
        'esri/renderers/ClassBreaksRenderer',
        'esri/symbols/PictureMarkerSymbol',
        'fcl/FlareClusterLayer_v4'

      ],this.options).catch(err => {
        console.error('ArcGIS error: ', err)
      }) // TODO: ezeknek a cuccoknak elérhetőnek kellene lennie az egész komponensben, mert így muszáj mindent ebbe az egy metódusba belegyúrni, ami nem túl jó gyakorlat.

      console.log(this.langPack)
      //---------------------------------------------
      this.map = new Map({
        basemap: 'gray-vector',
      })
      this.mapView = new MapView({
          container: this.mapEl.nativeElement,
          center: [19.0402,47.4979],
          zoom: 12,
          map: this.map
      })

      //this.mapView.ui.remove("zoom");

      this.mapView.when(() => {
        let layerList = new LayerList({
          container: document.createElement("div"),
          view: this.mapView
        });

        /*if(this.entity && this.entity.position.lon.length > 0){
          this.esriMapService.mapView.goTo({
            center: [parseFloat(this.entity.position.lon), parseFloat(this.entity.position.lat)],
            zoom: 18
          });
        }*/

        let layerListExpand = new Expand({
          expandIconClass: "esri-icon-layer-list",
          view: this.mapView,
          content: layerList.domNode
        });
        this.mapView.ui.add(layerListExpand, "bottom-right");

       const zoom = new Zoom()

        /*setTimeout(() => {
          this.mapView.ui.add(zoom, "top-left");
        }, 1000);*/
      })

      let chargers = new FeatureLayer({
        url: "https://kapu.budapestkozut.hu/arcgis/rest/services/kozutfigyelo/kozutfigyelo/MapServer/1",
        visible: false,
        title: this.langPack.chargerLayer,
        definitionExpression: 'nsd = 1'
      });
     
      
      let parkingZones = new FeatureLayer({
        url: "https://kapu.budapestkozut.hu/arcgis/rest/services/kozutfigyelo/kozutfigyelo/MapServer/4",
        visible: false,
        labelingInfo: [{     
          symbol: {
            type: "text", 
            color: "black",
            haloColor: "white",
            font: {         
              family: "Arial",
              size: 12,
              weight: "bold"
            }
          },
          labelPlacement: "above-center",
          labelExpressionInfo: {
            expression: "return $feature[\"mxr\"] + \" Ft\";"
          }
        }],        
        definitionExpression: `nhb not like 'Védett övezet'`,
        title: this.langPack.parkingZoneLayer
      });
 
      let restrictedZones = new FeatureLayer({
        url: "https://kapu.budapestkozut.hu/arcgis/rest/services/kozutfigyelo/kozutfigyelo/MapServer/3",
        visible: false,       
        title: this.langPack.restrictedZoneLayer,
        labelingInfo: [{
          symbol: {
            type: "text", 
            color: "black",
            haloColor: "white",
            font: {
              family: "Arial",
              size: 12,
              weight: "bold"
            }
          },         
          labelExpressionInfo: {
            expression: `var code = $feature.tbr;var labelstring = When(code == 1, "Lakó és pihenő övezet", code == 2, "30-as övezet", code != 1 && code != 2);return labelstring;`
          }
        }]
      });    

      let maintainedRoads = new FeatureLayer({
        url: "https://kapu.budapestkozut.hu/arcgis/rest/services/kozutfigyelo/kozutfigyelo/MapServer/2",
        visible: false,
        title: this.langPack.maintainedRoadLayer
      });

      let trafficLights = new FeatureLayer({
        url: "https://kapu.budapestkozut.hu/arcgis/rest/services/kozutfigyelo/kozutfigyelo/MapServer/0",
        visible: false,
        title: this.langPack.trafficLightLayer
      });

      this.map.add(maintainedRoads);
      this.map.add(parkingZones);
      this.map.add(chargers);
      this.map.add(restrictedZones);
      this.map.add(trafficLights);

       // Kamera objektumok 2db, ezt a tömböt kellene feltölteni Graphic objektumokkal, amik megegyeznek ezzel a mintával
       let camera_objects: Array<any> = []
       let total = 1
       let currentPage = 1
       for(let i = 0; i <= total; i++)
       {
         let cameraList = await this.ikszrApiService.list('cameras',currentPage,10,'')
         total = cameraList.total
         currentPage++
         i = i + 10

         for(let j = 0; j < cameraList.docs.length; j++) camera_objects.push({      
            
             longitude: parseFloat(cameraList.docs[j].position.lon),
             latitude: parseFloat(cameraList.docs[j].position.lat)+ this.createHash(cameraList.docs[j].externalId) * 0.00001,          
             type: 'cameras',
             ObjectID: j*i,
             externalId: cameraList.docs[j].externalId,
             location: cameraList.docs[j].locationDescription,
             lastUpdate: cameraList.docs[j].lastUpdate
           
         })
       }
      let renderer = new ClassBreaksRenderer({
        defaultSymbol: new PictureMarkerSymbol('/assets/icon/cam-act-map.svg', '37px', '45px')
      });
      renderer.field = "clusterCount";                   

      renderer.addClassBreakInfo(0, Infinity, new PictureMarkerSymbol('/assets/icon/cam-act-map-0.svg', '37px', '45px'));  
              
      const options = {
        id: 'cameras',
        title: this.langPack.cameraLayer,
        clusterRenderer: renderer,
        clusterMinCount: 4,
        spatialReference: new SpatialReference({ "wkid": 4326 }),
        displaySubTypeFlares: false,
        maxSingleFlareCount: 8,
        clusterRatio: 100,
        clusterAreaDisplay: false,
        clusterToScale: 1128.497176,              
        xPropertyName: 'longitude',
        yPropertyName: 'latitude',
        data: camera_objects,
        textSymbol: new TextSymbol({
          color: new Color([255, 255, 255]),
          font: {
              size:9,
              family: "arial",
              weight: "bold"
          },
          yoffset: -1, 
          xoffset: -1
        })                       
      }

      let cameras = new fcl.FlareClusterLayer(options);
      
      this.map.add(cameras);   

       // vjt objektumokat tartalmazó tömb
       let vjt_objects = []
       total = 1
       currentPage = 1
       for(let i = 0; i <= total; i++)
       {
         let vjtList = await this.ikszrApiService.list('vjts',currentPage,10,'')
         currentPage++
         i=i+10
         total = vjtList.total
         for(let j = 0; j < vjtList.docs.length; j++){

          vjt_objects.push({            
              longitude: parseFloat(vjtList.docs[j].position.lon),
              latitude: parseFloat(vjtList.docs[j].position.lat),            
              type: 'vjts',
              ObjectID: j*i,
              externalId: vjtList.docs[j].externalId,
              location: vjtList.docs[j].locationDescription,
              lastUpdate: vjtList.docs[j].lastUpdate            
          })
         }
       }
      
      let vjtRenderer = new ClassBreaksRenderer({
        defaultSymbol: new PictureMarkerSymbol('/assets/icon/VJT-green-map.svg', '37px', '45px', '37px', '45px')
      });
      vjtRenderer.field = "clusterCount";                   

      vjtRenderer.addClassBreakInfo(0, Infinity, new PictureMarkerSymbol('/assets/icon/vjt-map.svg', '37px', '45px'));  
              
      const vjtOptions = {
        id: 'vjt',
        title: this.langPack.vjtLayer,
        clusterRenderer: vjtRenderer,
        clusterMinCount: 2,
        spatialReference: new SpatialReference({ "wkid": 4326 }),
        displaySubTypeFlares: false,
        maxSingleFlareCount: 8,
        clusterRatio: 100,
        clusterAreaDisplay: false,
        clusterToScale: 1128.497176,              
        xPropertyName: 'longitude',
        yPropertyName: 'latitude',
        data: vjt_objects,
        textSymbol: new TextSymbol({
          color: new Color([0, 0, 0]),
          font: {
              size:9,
              family: "arial",
              weight: "bold"
          },
          yoffset: -1, 
          xoffset: -1
        })                       
      }

      let vjts = new fcl.FlareClusterLayer(vjtOptions);
      this.map.add(vjts)


       //parkolókat tartalmazó tömb, itt van +2 attribútum annak érdekében, hogy az esri apival generált popup táblázat megtalálja az adatokat
       let parking_facility_objects = []
       total = 1
       currentPage = 1
       for(let i = 0; i <= total; i++)
       {
         let pfList = await this.ikszrApiService.list('parking-facilities',currentPage,10,'')
         currentPage++
         total = pfList.total
         i = i + 10
         for(let j = 0; j < pfList.docs.length; j++){
         let symbolUrl;
         if(pfList.docs[j].entity_subtype == 1){
              if (pfList.docs[j].free_capacity == 0) {
                  symbolUrl = "/assets/icon/g-red-map.svg"
              }
              else if (pfList.docs[j].free_capacity < pfList.docs[j].capacity*0.25 && pfList.docs[j].free_capacity != 0) {
                  symbolUrl = "/assets/icon/g-orange-map.svg"
              }
              else{
                  symbolUrl = "/assets/icon/g-green-map.svg"
              }
          }
          else if(pfList.docs[j].entity_subtype == 2){
              if (pfList.docs[j].free_capacity == 0) {
                  symbolUrl = "/assets/icon/pnr-red-map.svg"
              }
              else if (pfList.docs[j].free_capacity < pfList.docs[j].capacity*0.25 && pfList.docs[j].free_capacity != 0) {
                  symbolUrl = "/assets/icon/pnr-orange-map.svg"
              }
              else{
                  symbolUrl = "/assets/icon/pnr-green-map.svg"
              }

          }
          else if(pfList.docs[j].entity_subtype == 5){
              if (pfList.docs[j].free_capacity == 0) {
                  symbolUrl = "/assets/icon/p-red-map.svg"
              }
              else if (pfList.docs[j].free_capacity < pfList.docs[j].capacity*0.25 && pfList.docs[j].free_capacity != 0) {
                  symbolUrl = "/assets/icon/p-orange-map.svg"
              }
              else{
                  symbolUrl = "/assets/icon/p-green-map.svg"
              }
          }

          if (pfList.docs[j].status === "inactive" || pfList.docs[j].status === "ignored") {
            symbolUrl = "/assets/icon/p-red-map.svg"
          }

            parking_facility_objects.push(new Graphic({
              geometry:{
                type: 'point',
                longitude: pfList.docs[j].position.lon,
                latitude: pfList.docs[j].position.lat
              },
              attributes:{
                type: 'parking-facilities',
                ObjectID: j*i,
                externalId: pfList.docs[j].externalId,
                location: pfList.docs[j].locationDescription,
                lastUpdate: pfList.docs[j].lastUpdate,
              },
              symbol: new PictureMarkerSymbol(symbolUrl, '37px', '45px')
            }))

         }
       }
      let parking_facilites = new GraphicsLayer({
        graphics: parking_facility_objects,
        title: this.langPack.parkingFacilityLayer,
      });
      this.map.add(parking_facilites)

      /*let track = new Track({
        view: this.mapView
      });
      this.mapView.ui.add(track, "top-left");*/

      this.mapReady.next(true)

      setInterval(() => {
        if(this.mapView.on && !this.mapEventSubscribed) {
          this.mapView.on('click', (event) =>{
            this.mapView.hitTest(event).then((response)=>{
              if (this.platform.is('desktop') && this.platform.width() >= 800) {
                setTimeout(() => {
                  if(response.results[0].graphic.attributes.type == "cameras") {
                    this.router.navigate(['/list/camerahistory'], { queryParams: { id: response.results[0].graphic.attributes.externalId }})                    
                    this.mapView.goTo({
                      center: [parseFloat(response.results[0].graphic.geometry.longitude), parseFloat(response.results[0].graphic.geometry.latitude)],
                      zoom: this.mapView.zoom > 18 ? this.mapView.zoom : 18
                    })
                  }
                  if(response.results[0].graphic.attributes.type == "parking-facilities") {
                    this.router.navigate(['/list/pfhistory'], { queryParams: { id: response.results[0].graphic.attributes.externalId }})
                    this.mapView.goTo({
                      center: [parseFloat(response.results[0].graphic.geometry.longitude), parseFloat(response.results[0].graphic.geometry.latitude)],
                      zoom: this.mapView.zoom > 18 ? this.mapView.zoom : 18
                    })
                  }
                  if(response.results[0].graphic.attributes.type == "vjts") {
                    this.router.navigate(['/list/vjtinfo'], { queryParams: { id: response.results[0].graphic.attributes.externalId }})
                    this.mapView.goTo({
                      center: [parseFloat(response.results[0].graphic.geometry.longitude), parseFloat(response.results[0].graphic.geometry.latitude)],
                      zoom: this.mapView.zoom > 18 ? this.mapView.zoom : 18
                    })
                  }
                },100)
              } else {
                this.router.navigate(['/map'], { queryParams: { id: response.results[0].graphic.attributes.externalId,type: response.results[0].graphic.attributes.type}})
                setTimeout(() => {
                  if(response.results[0].graphic.attributes.type == "cameras") {
                    this.router.navigate(['/list/camerahistory'], { queryParams: { id: response.results[0].graphic.attributes.externalId }})
                  }
                  if(response.results[0].graphic.attributes.type == "parking-facilities") {
                    this.router.navigate(['/list/pfhistory'], { queryParams: { id: response.results[0].graphic.attributes.externalId }})
                  }
                },100)
              }
            })
          })
        }
        this.mapEventSubscribed = true

        document.getElementById('map-loading').classList.add('desktop-display-none');
      },1000)

      return resolve(true)
    })

  }

}
