import { Component, OnInit, Input } from '@angular/core';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { IkszrApiService } from 'src/app/core/services/ikszr-api/ikszr-api.service';
import { AppConfig } from 'src/app/core/config/app-config.config';

@Component({
  selector: 'app-parking-facility-info',
  templateUrl: './parking-facility-info.component.html',
  styleUrls: ['./parking-facility-info.component.scss'],
})
export class ParkingFacilityInfoComponent implements OnInit {

  @Input() parkingFacilityInstance : IkszrEntity = new IkszrEntity()
  prepared: boolean = false
  langPack : any
  constructor(public ikszrApiService:IkszrApiService) { }

  async ngOnInit() {
    this.getLangPack()
    await this.getInfo()
  }

  async getInfo()
  {
    let getInfoRes = await this.ikszrApiService.getParkingFacilityInfo(this.parkingFacilityInstance.externalId, 0)
    if(!getInfoRes.error) this.parkingFacilityInstance.info =  getInfoRes.data[0]
    if(getInfoRes.error) this.parkingFacilityInstance.info =  {
      free_capacity: '---',
      capacity: '---',
      type: '---',
      created_at: '---'
    }
    this.prepared = true
    return true
  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  langPacks = {
    hu: {
      freePlaces: 'Szabad helyek',
      capacity: 'Összes hely',
      type: 'Típus',
      createdAt: 'Frissítve',
    },
    en: {
      freePlaces: 'Free places',
      capacity: 'Capacity',
      type: 'Type',
      createdAt: 'Date',
    }
  }

}
