import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  @Input() headerText: string = ''
  constructor(public router: Router) { }

  ngOnInit() {}

  userPageNavigation()
  {
    this.router.navigate(['/user'])
  }
}
