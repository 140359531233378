import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiRequestService } from '../api-request/api-request.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config/app-config.config';
import { User } from '../../models/user/user.model';
import { IkszrEntity } from '../../models/ikszr-entity/ikszr-entity.model';

@Injectable({
  providedIn: 'root',
})

export class IkszrApiService {

  public currentUser : User = new User()
  public isLoggedIn : boolean = false

  constructor(public apiRequestService:ApiRequestService, public http:HttpClient) {

  }

  public async list(type:string, page:Number, limit:Number, filter:string) {
    if(!page) page = 1
    if(!limit) limit = AppConfig.BASIC_PAGE_SIZE
    if(!filter) filter = ''

    let list: Array<any> = []
    let res = await this.apiRequestService.post('/ikszr/list', {
      page:page,
      limit:limit,
      objectType:type,
      filter:filter
    })

    if(res.error) {
      console.error('IkszrService error (list):', res.error)
    } else {
      for(let i = 0; i < res.data.docs.length; i++)
      {
        list.push( this.reqDataToInstace(res.data.docs[i]) )
      }
    }
    return { docs: list, limit:limit, page: res.data.page, pages: res.data.pages, total: res.data.total}
  }

  public async get(externalId:string, type: string) {

    let res = await this.apiRequestService.post('/ikszr/get', {
      objectType:type,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (get):', res.error)
      return { error: res.error, data: null}

    } else {
      return { error: null, data:{ instance: this.reqDataToInstace(res.data.instance)} }
    }
  }

  public async getCameraImage(externalId:string, thumbnail: number) {

    let res = await this.apiRequestService.post('/ikszr/cameraimage', {
      thumbnail:thumbnail,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (getCameraImage):', res.error)
      return { error: res.error, data: null}

    } else {
      return { error: null, data: res.data}
    }
  }

  public async getCameraHistoryImage(externalId:string, time: string) {

    let res = await this.apiRequestService.post('/ikszr/camerahistoryimage', {
      time:time,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (getCameraHistoryImage):', res.error)
      return { error: res.error, data: null}

    } else {
      return { error: null, data: res.data}
    }
  }

  public async getVjtImage(externalId:string, thumbnail: number) {

    let res = await this.apiRequestService.post('/ikszr/vjtimage', {
      thumbnail:thumbnail,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (getVjtImage):', res.error)
      return { error: res.error, data: null}

    } else {
      return { error: null, data: res.data}
    }
  }

  public async getParkingFacilityInfo(externalId:string, thumbnail: number) {
    let res = await this.apiRequestService.post('/ikszr/pfinfo', {
      limit:1,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (getParkingFacilityInfo):', res.error)
      return { error: res.error, data: null}
    } else {
      return { error: null, data: res.data}
    }
  }

  public async getParkingFacilityHistory(externalId:string, dayOfWeek: number) {
    let res = await this.apiRequestService.post('/ikszr/pfhistory', {
      dayOfWeek:dayOfWeek,
      externalId:externalId
    })

    if(res.error) {
      console.error('IkszrService error (getParkingFacilityHistory):', res.error)
      return { error: res.error, data: null}
    } else {
      return { error: null, data: res.data}
    }
  }

  //req to obj
  private reqDataToInstace(data:any) : any {
    let obj = new IkszrEntity()
    if(data.external_id) obj.externalId = data.external_id
    if(data.entity_id) obj.entityId = data.entity_id
    if(data.last_data) if(data.last_data.length > 0) obj.lastUpdate = data.last_data
    if(data.entity_type) obj.type = data.entity_type
    if(data.type) obj.entity_subtype = data.type
    if(data.location_description) obj.locationDescription = data.location_description
    if(data.isBookmark) obj.isBookmark = data.isBookmark
    if(data.fee) obj.fee = data.fee
    if(data.time_dimension) obj.time_dimension = data.time_dimension
    if(data.payment_with_machine) obj.payment_with_machine = data.payment_with_machine
    if(data.payment_with_app) obj.payment_with_app = data.payment_with_app
    if(data.payment_with_sms) obj.payment_with_sms = data.payment_with_sms
    if(data.payment_with_call) obj.payment_with_call = data.payment_with_call
    if(data.payment_with_parkl) obj.payment_with_parkl = data.payment_with_parkl
    if(data.opening_hours) obj.opening_hours = data.opening_hours
    if(data.protected) obj.protected = data.protected
    if(data.camera) obj.camera = data.camera
    if(data.barrier) obj.barrier = data.barrier
    if(data.height_restriction) obj.height_restriction = data.height_restriction
    if(data.capacity) obj.capacity = data.capacity
    if(data.free_capacity) obj.free_capacity = data.free_capacity
    if(data.electric_chargers) obj.electric_chargers = data.electric_chargers
    if(data.contact) obj.contact = data.contact
    if(data.status) obj.status = data.status
    if(data.fee_night) obj.fee_night = data.fee_night
    if(data.daily_hours) obj.daily_hours = data.daily_hours
    if(data.nightly_hours) obj.nightly_hours = data.nightly_hours
    if(data.latitude && data.longitude) obj.position = {
      lat: data.latitude,
      lon: data.longitude
    }
    //obj.status = "inactive"
    /*obj.fee = 100
    obj.fee_night = 50
    obj.daily_hours = "08:00-17:59"
    obj.nightly_hours = "18:00-07:59"
    obj.time_dimension = "óra"*/

    obj.isInactive = obj.checkInactive()
    return obj
  }
}
