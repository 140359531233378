export class AppConfig {
  public static API_URL='https://kozutfigyelo-back.budapestkozut.hu/api'
  //public static API_URL='http://127.0.0.1:8000/api'
  public static BASIC_PAGE_SIZE = 15
  public static ENTITY_INACTIVE_TIME = 7200000 //2h
  public static HISTORY_MIN = 7200000 //2h
  public static CAMERA_HISTORY_MAX_TIME_BEFORE_ERROR = 900 //sec, 15min
  public static LANGS : Array<string> = [
    'hu',
    'en'
  ]

}
