import { Component, OnInit, Input } from '@angular/core';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { IkszrApiService } from 'src/app/core/services/ikszr-api/ikszr-api.service';

@Component({
  selector: 'app-camera-image',
  templateUrl: './camera-image.component.html',
  styleUrls: ['./camera-image.component.scss'],
})
export class CameraImageComponent implements OnInit {

  @Input() cameraInstance : IkszrEntity = new IkszrEntity()
  prepared: boolean = false
  constructor(public ikszrApiService:IkszrApiService) { }

  async ngOnInit() {
    await this.getImage()
  }

  async getImage()
  {
    let getImgRes = await this.ikszrApiService.getCameraImage(this.cameraInstance.externalId, 0)
    if(!getImgRes.error)  this.cameraInstance.image = {
      base64Img : getImgRes.data.image,
      createdAt: getImgRes.data.createdAt
    }
    this.prepared = true
    return true
  }

}
