import { Component, OnInit, Input } from '@angular/core';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';
import { AppConfig } from 'src/app/core/config/app-config.config';

@Component({
  selector: 'app-navigate-to',
  templateUrl: './navigate-to.component.html',
  styleUrls: ['./navigate-to.component.scss'],
})
export class NavigateToComponent implements OnInit {
  @Input() ikszrEntity : IkszrEntity = new IkszrEntity()
  langPack : any

  constructor() {

  }

  ngOnInit() {
    this.getLangPack()
  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  langPacks = {
    hu: {
      navigate: 'Navigálás'
    },
    en: {
      navigate: 'Navigate'
    }
  }

}
