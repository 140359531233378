import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss'],
})
export class CameraModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
