import { Component, ViewChild, ElementRef } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './core/services/user/user.service';
import { AppConfig } from './core/config/app-config.config';
import { PushNotificationService } from './core/services/push-notification/push-notification.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { EsriMapService } from './core/services/esri-map/esri-map.service';
import { BookmarkService } from './core/services/bookmark/bookmark.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public langPacks = {
    hu: {
      homeMenuItemText: 'Hírek',
      userMenuItemText: 'Felhasználó',
      mapMenuItemText: 'Térkép',
      searchMenuItemText: 'Lista',
      helpMenuItemText: 'Súgó',
      bookmarkMenuItemText: 'Kedvencek',
      settingsMenuItemText: 'Beállítások',
      parklMenuItemText: 'Parkl alkalmazás',
      notificationMenuItemText: 'Értesítések',
      termsAndCondsItemText: 'Általános szerződési feltételek',
      listName: 'Lista',
      bookmarksName: 'Kedvencek',
      mapName: 'Térkép'
    },
    en: {
      homeMenuItemText: 'News',
      userMenuItemText: 'User',
      mapMenuItemText: 'Map',
      searchMenuItemText: 'List',
      helpMenuItemText: 'Help',
      parklMenuItemText: 'Parkl app',
      bookmarkMenuItemText: 'Favorites',
      settingsMenuItemText: 'Settings',
      notificationMenuItemText: 'Notifications',
      termsAndCondsItemText: 'Terms and conditions',
      listName: 'List',
      bookmarksName: 'Favorites',
      mapName: 'Map'
    }
  }
  public appPages = [
    {
      title: this.getLangPack().homeMenuItemText,
      url: '/home',
      icon: 'paper',
      isTermsAndConds: false,
      hiddenOnDesktop: false,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().searchMenuItemText,
      url: '/list',
      icon: 'list',
      isTermsAndConds: false,
      hiddenOnDesktop: false,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().mapMenuItemText,
      url: '/map',
      icon: 'map',
      isTermsAndConds: false,
      hiddenOnDesktop: false,
      hiddenFrom800: true
    },
    {
      title: this.getLangPack().bookmarkMenuItemText,
      url: '/bookmark',
      icon: 'star',
      isTermsAndConds: false,
      hiddenOnDesktop: true,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().notificationMenuItemText,
      url: '/notification',
      icon: 'notifications',
      isTermsAndConds: false,
      hiddenOnDesktop: true,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().settingsMenuItemText,
      url: '/settings',
      icon: 'contact',
      isTermsAndConds: false,
      hiddenOnDesktop: false,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().helpMenuItemText,
      url: '/help',
      icon: 'help-circle',
      isTermsAndConds: false,
      hiddenOnDesktop: false,
      hiddenFrom800: false
    },
    {
      title: this.getLangPack().termsAndCondsItemText,
      url: '/home/terms',
      icon: '',
      isTermsAndConds: true,
      hiddenOnDesktop: false,
      hiddenFrom800: false
    }
  ]

  public currentRoute :string = ''

  @ViewChild('map') mapEl: ElementRef;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userService:UserService,
    public router:Router,
    public route: ActivatedRoute,
    public menu: MenuController,
    public pushNotificationService:PushNotificationService,
    public esriMapService:EsriMapService,
    private bookmarkService: BookmarkService,
    public fcm: FCM
  ) {
    this.initializeApp();

    this.router.events.subscribe((val:any) => {
      this.currentRoute = this.router.url
    })
  }

  async ngOnInit() {
    let checkRes = await this.userService.checkToken()
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
    }
    await this.esriMapService.getGeo()

    this.fcm.subscribeToTopic('kozutnoti')

    this.fcm.getToken().then(async (token:string)=>{
      localStorage.setItem("fcmToken", token)
      if(this.userService.isLoggedIn) await this.userService.fcmToken(fcmToken)
    })

    this.fcm.onNotification().subscribe(data=>{
      if(data.wasTapped){
        console.log("Received in background")
      } else {
        console.log("Received in foreground")
      }
    })

    this.fcm.onTokenRefresh().subscribe(async (token:string)=>{
      console.log("Refreshed token:", token)
      localStorage.setItem("fcmToken", token)
      if(this.userService.isLoggedIn) await this.userService.fcmToken(fcmToken)
    })

    let fcmToken = localStorage.getItem("fcmToken")
    if(fcmToken)
    {
      if(fcmToken.length > 0)
      {
        if(this.userService.isLoggedIn) await this.userService.fcmToken(fcmToken)
      }
    }

    if(this.userService.isLoggedIn) {
      const hasBookmark = await this.bookmarkService.hasBookmark();
      if (hasBookmark.data.hasBookmark === 1) {
        this.router.navigate(['/bookmark'])
      }
    }

    if(this.router.url.indexOf('/map') > -1)
    {
      this.esriMapService.isMapHidden = false
      this.menu.get().then((menu: HTMLIonMenuElement) => {
        menu.swipeGesture = false
      })
    } else {
      this.esriMapService.isMapHidden = this.platform.width() >= 800 ? false : true
      this.menu.get().then((menu: HTMLIonMenuElement) => {
        menu.swipeGesture = true
      })
    }

    this.router.events.subscribe((val) => {
       if(val instanceof NavigationEnd)
       {
        if(this.router.url.indexOf('/map') > -1)
        {
          this.esriMapService.isMapHidden = false
          this.menu.get().then((menu: HTMLIonMenuElement) => {
            menu.swipeGesture = false
          })
        } else {
          this.esriMapService.isMapHidden = this.platform.width() >= 800 ? false : true
          this.menu.get().then((menu: HTMLIonMenuElement) => {
            menu.swipeGesture = true
          })
        }
       }
    })
  }

  public ngAfterViewInit() {
    this.esriMapService.mapEl = this.mapEl
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();
      if (this.platform.is('android')) {
        this.statusBar.styleLightContent();
      }
    });

  }

  public getLangPack() {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    return this.langPacks[lang]
  }

  navigate(event:Event, link:string)
  {
    this.router.navigate(['/'+link])
  }
}
