import { AppConfig } from '../../config/app-config.config';

export class IkszrEntity {
  externalId: string = ''
  entityId: string = ''
  lastUpdate: string = ''
  type: string = ''
  locationDescription: string = ''
  status: string = ''
  isBookmark: number = 0
  fee_night: number = 0
  daily_hours: string = "";
  nightly_hours: string = "";

  position: {
    lat: string,
    lon: string
  } = {
    lat: '',
    lon: ''
  }
  image: {
    base64Img : string,
    createdAt : string
  } = {
    base64Img : '',
    createdAt : '',
  }
  info:any = {

  }

  isInactive :boolean =true

  //parking facility special
  fee: number = 0
  time_dimension: string = ''
  payment_with_machine: number = 0
  payment_with_app: number = 0
  payment_with_sms: number = 0
  payment_with_call: number = 0
  payment_with_parkl: number = 0
  opening_hours: string = ''
  protected: number = 0
  camera: number = 0
  barrier: number = 0
  height_restriction: string = ''
  electric_chargers: string = ''
  contact: string = ''
  capacity: number = 0
  free_capacity: number = 0
  entity_subtype: string = ''

  public checkInactive()
  {
    if (this.free_capacity < 0 || this.capacity < 0) return true
    if (this.status === "inactive" || this.status === "ignored") return true
    if(this.lastUpdate.length > 0)
    {
      let match : Array<any> = this.lastUpdate.match(/^(\d+)-(\d+)-(\d+) (\d+)\:(\d+)\:(\d+)$/)
      let date = new Date(match[1], match[2] - 1, match[3], match[4], match[5], match[6])
      return new Date().getTime()-AppConfig.ENTITY_INACTIVE_TIME > date.getTime()
    } else  {
      return true
    }
  }

  getParkingFacilityColor() : string
  {
    if(this.status === "inactive" || this.status === "ignored") return "inactive"
    if(this.free_capacity == 0) return 'red'
    if(this.free_capacity/this.capacity <= 0.25) return 'orange'
    return 'green'
  }

  hasDifferentFees() {
    if (this.fee_night > 0 && this.fee_night !== this.fee) return true;
    return false;
  }
}
