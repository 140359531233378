import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { IkszrApiService } from 'src/app/core/services/ikszr-api/ikszr-api.service';
import { BehaviorSubject } from 'rxjs';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';

@Component({
  selector: 'app-camera-history',
  templateUrl: './camera-history.component.html',
  styleUrls: ['./camera-history.component.scss'],
})
export class CameraHistoryComponent implements OnInit {

  langPack : any
  minTime : number
  maxTime : number

  minTimeStr : string
  maxTimeStr : string

  rangeTime : number

  cameraId : string

  currentImage: {
    image: string,
    createdAt: string
  }

  prepared : boolean = false
  refreshSubject : BehaviorSubject<boolean> = new BehaviorSubject(true)
  entity : IkszrEntity = new IkszrEntity()

  lastUpdateTime : Date = new Date()

  constructor(public route: ActivatedRoute, public router:Router, public userService: UserService, public ikszrApiService:IkszrApiService) {
    this.minTime = new Date().getTime()-AppConfig.HISTORY_MIN
    this.maxTime = new Date().getTime()
    this.rangeTime = new Date().getTime()

    let min = new Date(this.minTime).getMinutes().toString().length == 1 ? '0'+new Date(this.minTime).getMinutes() : new Date(this.minTime).getMinutes()
    this.minTimeStr = new Date(this.minTime).getHours()+':'+min
    this.maxTimeStr = new Date(this.maxTime).getHours()+':'+min
  }

  ngOnInit() {
    this.getLangPack()
    this.route.queryParams.subscribe(async (params:any) => {
      this.cameraId = params.id
      let hour :string = new Date(this.maxTime).getHours().toString().length == 1 ? '0'+new Date(this.maxTime).getHours() : new Date(this.maxTime).getHours().toString()
      let min:string  = new Date(this.maxTime).getMinutes().toString().length == 1 ? '0'+new Date(this.maxTime).getMinutes() : new Date(this.maxTime).getMinutes().toString()
      await this.getImage(hour+':'+min)
      this.refreshSubject.next(true)
      this.refreshSubject.subscribe(async (val:boolean) => {
        let getRes = await this.ikszrApiService.get(this.cameraId, "cameras")
        if(!getRes.error) this.entity = getRes.data.instance

        if (!this.entity.checkInactive()) {
          let strTime = this.entity.lastUpdate.split(' ')
          this.lastUpdateTime = new Date(strTime[0]+"T"+strTime[1]+"Z")
          this.prepared = true
          const imgHeight = document.querySelector('img.camera-card-img').clientHeight
          document.querySelector('.image-container').setAttribute('style', `height: ${imgHeight + 14}px`)
        }
      })
    })
  }

  async rangeChange()
  {
    let hour :string = new Date(this.rangeTime).getHours().toString().length == 1 ? '0'+new Date(this.rangeTime).getHours() : new Date(this.rangeTime).getHours().toString()
    let min:string  = new Date(this.rangeTime).getMinutes().toString().length == 1 ? '0'+new Date(this.rangeTime).getMinutes() : new Date(this.rangeTime).getMinutes().toString()
    await this.getImage(hour+':'+min)
  }

  async getImage(time:string)
  {
    this.prepared = false
    if(this.cameraId.length > 0)
    {
      let getRes = await this.ikszrApiService.getCameraHistoryImage(this.cameraId, time)
      if(!getRes.error)
      {
        this.currentImage = getRes.data
      } else {
        console.error('CameraHistoryComponent:'+ getRes.error)
      }
    } else {
      console.error('CameraHistoryComponent: Camera id not found!')
    }
    this.prepared = true
  }

  getLangPack() : boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
       localStorage.setItem('lang', 'hu')
       lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  isUpdated()
  {
    return (new Date().getTime()/1000) - (this.lastUpdateTime.getTime()/1000) < AppConfig.CAMERA_HISTORY_MAX_TIME_BEFORE_ERROR
  }

  langPacks = {
    hu: {
      menuItemText: 'Kamera',
      imageUpdateError: 'A kamerakép nem frissült!',
      loading: 'Adatok betöltése',
      inactiveEntity: 'Inaktív kamera'
    },
    en: {
      menuItemText: 'Camera',
      imageUpdateError: 'Image has not been updated!',
      loading: 'Loading data',
      inactiveEntity: 'Inactive camera'
    }
  }

}
