import { Injectable } from '@angular/core';
import { ApiRequestService } from '../api-request/api-request.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config/app-config.config';
import { IkszrEntity } from '../../models/ikszr-entity/ikszr-entity.model';

@Injectable({
  providedIn: 'root',
})

export class BookmarkService {


  constructor(public apiRequestService:ApiRequestService, public http:HttpClient) {

  }

  public async list(objType:string, page:Number, limit:Number, filter:string) {
    if(!page) page = 1
    if(!limit) limit = AppConfig.BASIC_PAGE_SIZE
    if(!filter) filter = ''

    let list: Array<any> = []

    let res = await this.apiRequestService.get('/bookmark/list?type='+objType+'&page='+page+'&limit='+limit+'&filter='+filter, {  })

    if(res.error) {
      console.error('BookmarkService error (list):', res.error)
    } else {
      for(let i = 0; i < res.data.docs.length; i++)
      {
        list.push( this.reqDataToInstace(res.data.docs[i]) )
      }
    }
    return { docs: list, limit:limit, page: res.data.page, pages: res.data.pages, total: res.data.total}
  }

  public async create(externalId:string, type: string) {

    let res = await this.apiRequestService.post('/bookmark/create', {
      type:type,
      externalId:externalId
    })

    if(res.error) {
      console.error('BookmarkService error (create):', res.error)
      return { error: res.error, data: null}

    } else {
      return { error: null, data: res.data}
    }
  }

  public async hasBookmark() {

    let res = await this.apiRequestService.get('/bookmark/hasbookmark', {
    })

    if(res.error) {
      console.error('BookmarkService error (hasBookmark):', res.error)
      return { error: res.error, data: null}
    } else {
      return { error: null, data: res.data}
    }
  }

  public async delete(externalId:string) {

    let res = await this.apiRequestService.get('/bookmark/delete?externalId='+externalId, {
    })

    if(res.error) {
      console.error('BookmarkService error (getCameraImage):', res.error)
      return { error: res.error, data: null}
    } else {
      return { error: null, data: res.data}
    }
  }

  //req to obj
  private reqDataToInstace(data:any) : any {
    let obj = new IkszrEntity()
    if(data.external_id) obj.externalId = data.external_id
    if(data.entity_id) obj.entityId = data.entity_id
    if(data.last_data) if(data.last_data.length > 0) obj.lastUpdate = data.last_data
    if(data.entity_type) obj.type = data.entity_type
    if(data.type) obj.entity_subtype = data.type
    if(data.location_description) obj.locationDescription = data.location_description
    if(data.isBookmark) obj.isBookmark = data.isBookmark
    if(data.fee) obj.fee = data.fee
    if(data.payment_with_machine) obj.payment_with_machine = data.payment_with_machine
    if(data.payment_with_app) obj.payment_with_app = data.payment_with_app
    if(data.payment_with_sms) obj.payment_with_sms = data.payment_with_sms
    if(data.payment_with_call) obj.payment_with_call = data.payment_with_call
    if(data.opening_hours) obj.opening_hours = data.opening_hours
    if(data.protected) obj.protected = data.protected
    if(data.camera) obj.camera = data.camera
    if(data.barrier) obj.barrier = data.barrier
    if(data.height_restriction) obj.height_restriction = data.height_restriction
    if(data.capacity) obj.capacity = data.capacity
    if(data.free_capacity) obj.free_capacity = data.free_capacity
    if(data.electric_chargers) obj.electric_chargers = data.electric_chargers
    if(data.contact) obj.contact = data.contact
    if(data.latitude && data.longitude) obj.position = {
      lat: data.latitude,
      lon: data.longitude
    }
    obj.isInactive = obj.checkInactive()
    return obj
  }

}
