import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApiRequestService } from './services/api-request/api-request.service';
import { UserService } from './services/user/user.service';
import { IkszrApiService } from './services/ikszr-api/ikszr-api.service';
//import { PushNotificationService } from './services/push-notification/push-notification.service';
import { BookmarkService } from './services/bookmark/bookmark.service';
import { EsriMapService } from './services/esri-map/esri-map.service';
//import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

@NgModule({
  declarations: [
  ],
  providers: [
    UserService,
    ApiRequestService,
    IkszrApiService,
    BookmarkService,
    EsriMapService,
    //PushNotificationService,
    //Push
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core:CoreModule ){
    if (core) {
      throw new Error("You should import core module only in the root module")
    }
  }
}
