import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config/app-config.config';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})

export class ApiRequestService {

  constructor(private http: HttpClient) {

  }

  public hasValidToken(): boolean {
    let jwtHelper = new JwtHelperService()
    let token = localStorage.getItem('token')
    if(!token) return false
    return !jwtHelper.isTokenExpired(token)
  }

  public get(path:string, headers: any) {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {


      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.hasValidToken() ? localStorage.getItem('token') : ''
      })

      let options = {
        headers: headers
      }

      this.http.get(AppConfig.API_URL + path, options).subscribe(
        (res:any) => {
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          console.log(failRes)
          return resolve({error:failRes.error.message})
        }
      )

    })
  }

  public post(path:string, data: any) {
    return new Promise<{error:string, data:any}>( (resolve:Function) => {

      let headers = this.prepareHeaders()
      if(headers.error) return resolve({error:headers.error})

      this.http.post(AppConfig.API_URL + path, data, { 'headers': headers.headers } ).subscribe(
        (res:any) => {
          return resolve({error:null, data:res.data})
        },
        (failRes: any) => {
          console.log(failRes)
          return resolve({error:failRes.error.message})
        }
      )

    })
  }

  private prepareHeaders() : {error:string, headers:HttpHeaders}
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.hasValidToken() ? localStorage.getItem('token') : ''
    })
    return {error:null, headers :headers }
  }

}
