import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app-config.config';
//import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

@Injectable({
  providedIn: 'root',
})

export class PushNotificationService {

  constructor(/*public push:Push*/) {
/*    this.push.hasPermission().then((res: any) => {
      if (res.isEnabled) {
        console.log('We have permission to send push notifications');
      } else {
        console.log('We do not have permission to send push notifications');
      }
    })

    this.push.createChannel({
     id: "testchannel1",
     description: "My first test channel",
     // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
     importance: 3
   }).then(() => console.log('Channel created'));*/
  }

}
