import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/core/config/app-config.config';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { IkszrApiService } from 'src/app/core/services/ikszr-api/ikszr-api.service';
import { IkszrEntity } from 'src/app/core/models/ikszr-entity/ikszr-entity.model';

@Component({
  selector: 'app-vjt-info',
  templateUrl: './vjt-info.component.html',
  styleUrls: ['./vjt-info.component.scss'],
})
export class VjtInfoComponent implements OnInit {

  langPack: any
  externalId: string
  entity: any = new IkszrEntity()
  prepared: boolean = false

  constructor(
    public userService: UserService,
    public route: ActivatedRoute,
    public router: Router,
    public ikszrApiService: IkszrApiService
  ) { }

  async ngOnInit() {
    this.getLangPack();
    this.route.queryParams.subscribe(async (params: any) => {
      this.externalId = params.id
      const getRes = await this.ikszrApiService.get(this.externalId, 'vjts')
      if (!getRes.error) {
        this.entity = getRes.data.instance
        let getImgRes = await this.ikszrApiService.getVjtImage(this.externalId, 0)
        if(!getImgRes.error) {
          this.entity.image = {
            base64Img : getImgRes.data.image,
            createdAt: getImgRes.data.createdAt
          }
        }
        this.prepared = true
      }
    })
  }

  getLangPack(): boolean {
    let lang = localStorage.getItem('lang')
    if(AppConfig.LANGS.indexOf(lang) < 0)
    {
        localStorage.setItem('lang', 'hu')
        lang='hu'
    }
    this.langPack = this.langPacks[lang]
    return true
  }

  langPacks = {
    hu: {
      menuItemText: 'Változó jelzésképű tábla',
      vjtActive: 'Aktív',
      vjtInactive: 'Inaktív'
    },
    en: {
      menuItemText: 'Information display',
      vjtActive: 'Updated',
      vjtInactive: 'Outdated'
    }
  }

}
